import React, { useState, useContext, useEffect } from "react";
import { useMutation, gql, useLazyQuery } from "@apollo/client";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ChurchInformation from "./ChurchInformation";
import ChurchAddress from "./ChurchAddress";
import ContactOperation from "./ContactOperation";
import Summary from "./Summary";
import countries from "../Search/countries.json";
import AppBars from "../Header/AppBar";
import {
  is_email,
  is_phoneNumber,
  is_facebook,
  is_youTube,
  is_validURL,
  validateName,
} from "./Validate";
import useCities from "./useCities";
import UserContext from "../../context/auth-context";
import { useTranslation } from "react-i18next";

const enviroment = process.env.NODE_ENV;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const GET_CHURCH = gql`
  query ($id: ID!, $isEdit: Boolean) {
    church(id: $id, isEdit: $isEdit) {
      name
      title
      description
      image
      status
      establishedAt
      address {
        street
        city
        state
        zipcode
        country
      }
      contact {
        email
        phoneNumber
        website
        youtube
        facebook
      }
      additionalTabots {
        name
      }
    }
  }
`;

export default function Checkout({ isEdit, editChurchId }) {
  const user = useContext(UserContext);
  const { t } = useTranslation();

  const steps = [
    `${t("churchInfo.churchInfo")}`,
    `${t("address.address")}`,
    `${t("contact.contact")}`,
    `${t("summary.summary")}`,
  ];

  const POST_CHURCH = gql`
    mutation (
      $name: String
      $title: String
      $description: String
      $establishedAt: String
      $image: String
      $status: String
      $admin_id: Int
      $email: String!
      $facebook: String!
      $youtube: String!
      $phoneNumber: String!
      $website: String!
      $street: String
      $city: String
      $state: String
      $zipcode: String
      $country: String
      $additionalTabot: [AdditionalTabotInput]
    ) {
      addChurch(
        input: {
          address: {
            street: $street
            city: $city
            state: $state
            zipcode: $zipcode
            country: $country
          }
          church: {
            title: $title
            name: $name
            description: $description
            establishedAt: $establishedAt
            image: $image
            status: $status
            admin_id: $admin_id
          }
          contact: {
            phoneNumber: $phoneNumber
            email: $email
            website: $website
            youtube: $youtube
            facebook: $facebook
          }
          additionalTabot: $additionalTabot
          operation: {}
        }
      ) {
        name
      }
    }
  `;
  const UPDATE_CHURCH = gql`
    mutation (
      $name: String
      $title: String
      $description: String
      $establishedAt: String
      $image: String
      $status: String
      $admin_id: Int
      $email: String!
      $facebook: String!
      $youtube: String!
      $phoneNumber: String!
      $website: String!
      $street: String
      $city: String
      $state: String
      $zipcode: String
      $country: String
      $additionalTabot: [AdditionalTabotInput]
      $churchId: ID
    ) {
      updateChurch(
        churchId: $churchId
        input: {
          address: {
            street: $street
            city: $city
            state: $state
            zipcode: $zipcode
            country: $country
          }
          church: {
            title: $title
            name: $name
            description: $description
            establishedAt: $establishedAt
            image: $image
            status: $status
            admin_id: $admin_id
          }
          contact: {
            phoneNumber: $phoneNumber
            email: $email
            website: $website
            youtube: $youtube
            facebook: $facebook
          }
          additionalTabot: $additionalTabot
          operation: {}
        }
      ) {
        name
      }
    }
  `;

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ChurchInformation
            state={state}
            handleChange={handleChange}
            handleAdditionalTabot={handleAddition}
          />
        );
      case 1:
        return <ChurchAddress state={state} handleChange={handleChange} />;
      case 2:
        return <ContactOperation state={state} handleChange={handleChange} />;
      case 3:
        return (
          <Summary
            state={state}
            handleChange={handleChange}
            setActiveStep={setActiveStep}
            setState={setState}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [postChurch] = useMutation(POST_CHURCH);
  const [updateChurch] = useMutation(UPDATE_CHURCH);
  const [imageFile, setImageFile] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const [state, setState] = useState({
    name: "",
    title: "",
    description: "",
    image: "",
    status: "Pending",
    admin_id: user?.userDetails?.userId,
    establishedAt: null,
    email: "",
    facebook: "",
    phoneNumber: "",
    website: "",
    youtube: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    additionalTabots: [],
    additional: "",
    accepted: false,
  });

  const getAddressInformation = (address) => {
    if (!address) {
      return {};
    }
    const countryIndex = countries.findIndex(
      (country) => country.key === address.country
    );
    const stateIndex = countries[countryIndex].states.findIndex(
      (state) => state.abbreviation === address.state
    );
    const cityIndex = countries[countryIndex].states[
      stateIndex
    ].cities.findIndex((city) => city.name === address.city);
    return {
      country: countryIndex,
      state: stateIndex,
      city: cityIndex,
    };
    /*city: data?.church?.address?.city,
        state: data?.church?.address?.state,
        country: data?.church?.address?.country,*/
  };

  const { cities } = useCities(state);

  const [getChurch, { data, error, loading, refetch }] =
    useLazyQuery(GET_CHURCH);

  const getAdditionalTabotArray = (tabots) => {
    let tabotsArray = [];
    tabots.map((tabot) => {
      tabotsArray.push(tabot.name);
    });
    return tabotsArray;
  };

  useEffect(() => {
    if (isEdit) {
      //get details by id and populate respose to stateWithImage
      getChurch({
        variables: {
          id: editChurchId,
          isEdit,
        },
      });
      console.log({ data });
      setState({
        name: data?.church?.name,
        title: data?.church?.title,
        description: data?.church?.description,
        image: data?.church?.image,
        status: data?.church?.status,
        admin_id: user.userDetails.userId,
        establishedAt:
          data?.church?.establishedAt &&
          new Date(parseInt(data?.church?.establishedAt)),
        email: data?.church?.contact?.email,
        facebook: data?.church?.contact?.facebook,
        phoneNumber: data?.church?.contact?.phoneNumber,
        website: data?.church?.contact?.website,
        youtube: data?.church?.contact?.youtube,
        street: data?.church?.address?.street,
        zipcode: data?.church?.address?.zipcode,
        additionalTabots: data?.church?.additionalTabots
          ? getAdditionalTabotArray(data?.church?.additionalTabots)
          : [],
        additional: "",
        ...getAddressInformation(data?.church?.address),
        accepted: false,
      });
    }
  }, [data]);

  const handleChange = (e) => {
    console.log(e);
    if (e.target) {
      const { name, value } = e.target;
      console.log(value);
      if (e.target.name === "image") {
        setImageFile([...imageFile, e.target.files[0]]);
        let imageName = value.split("\\");
        let images;
        if (state.image === "") {
          images = imageName[imageName.length - 1];
        } else {
          images = `${state.image}+space+${imageName[imageName.length - 1]}`;
        }
        setState({
          ...state,
          [name]: images,
        });
      } else {
        console.log("set value", name, value);
        setState({
          ...state,
          [name]: value,
        });
        console.log(
          state[("additionalTabots", state["additional-tabot-name"])]
        );
      }
    }
  };

  const handleAddition = () => {
    setState({
      ...state,
      "additional-tabot-name": "",
      additionalTabots: [
        ...state.additionalTabots,
        state["additional-tabot-name"],
      ],
    });
  };

  const handleNext = async () => {
    console.log(state);
    setActiveStep(activeStep + 1);
    if (state["additional-tabot-name"]) {
      handleAddition();
    }
    if (activeStep === steps.length - 1) {
      setSpinner(true);
      if (imageFile.length !== 0) {
        let uploadedImages = "";
        imageFile.map(async (eachImage, i) => {
          const formData = new FormData();
          formData.append("file", eachImage, eachImage.name);
          await axios
            .post(
              "https://eotc-info-upload-server-ub7tml46pa-uc.a.run.app/uploads",
              formData
            )
            .then((res) => {
              if (uploadedImages === "") {
                uploadedImages = res.data.data;
              } else {
                uploadedImages = `${uploadedImages},${res.data.data}`;
              }
            });
          if (i === imageFile.length - 1) {
            const stateWithImage = {
              ...state,
              additionalTabot: state.additionalTabots.map((each) => ({
                name: each,
              })),
              image: uploadedImages,
              country: countries[state.country].key,
              state: countries[state.country].states[state.state].abbreviation,
              city: cities[state.city],
            };
            if (!isEdit) {
              postChurch({ variables: stateWithImage })
                .catch((error) => {
                  console.log(error);
                })
                .then((res) => {
                  console.log(res);
                  console.log("post successfull");
                  setActiveStep(steps.length);
                  setSpinner(false);
                });
            } else {
              const statewithImageEdited = {
                ...stateWithImage,
                image: `${stateWithImage.image},${state.image}`,
              };
              updateChurch({
                variables: { ...statewithImageEdited, churchId: editChurchId },
              })
                .catch((error) => {
                  console.log(error);
                })
                .then((res) => {
                  console.log(res);
                  console.log("post updated with image");
                  setActiveStep(steps.length);
                  setSpinner(false);
                });
            }
          }
        });
      } else {
        const stateWithOutImage = {
          ...state,
          additionalTabot: state.additionalTabots.map((each) => ({
            name: each,
          })),
          image: "",
          country: countries[state.country].key,
          state: countries[state.country].states[state.state].abbreviation,
          city: cities[state.city],
        };
        if (!isEdit) {
          postChurch({ variables: stateWithOutImage })
            .catch((error) => {
              console.log(error);
            })
            .then((res) => {
              console.log(res);
              console.log("post successfull");
              setActiveStep(steps.length);
              setSpinner(false);
            });
        } else {
          const statewithoutImageEdited = {
            ...stateWithOutImage,
            image: state.image,
          };
          updateChurch({
            variables: { ...statewithoutImageEdited, churchId: editChurchId },
          })
            .catch((error) => {
              console.log(error);
            })
            .then((res) => {
              console.log(res);
              console.log("post updated no image");
              setActiveStep(steps.length);
              setSpinner(false);
            });
        }
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const validateInput = (step) => {
    switch (step) {
      case 0:
        return !validateName(state.name) || !validateName(state.title)
          ? true
          : false;
      case 1:
        return state.street.length < 5 ||
          state.city === "" ||
          (state.zipcode.length !== 5 && state.zipcode.length !== 10) ||
          state.country === ""
          ? true
          : false;
      case 2:
        return !is_email(state.email) ||
          !is_phoneNumber(state.phoneNumber) ||
          (state.youtube && !is_youTube(state.youtube)) ||
          (state.facebook && !is_facebook(state.facebook)) ||
          (state.website && !is_validURL(state.website))
          ? true
          : false;
      case 3:
        return true;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      {console.log({ state })}
      <AppBars />
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position='absolute'
          color='default'
          className={classes.appBar}
        ></AppBar>
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                !spinner ? (
                  <React.Fragment>
                    {isEdit ? (
                      <>
                        <Typography variant='h5' gutterBottom>
                          Thank you for Updating this Church Information.
                        </Typography>
                        <Typography variant='subtitle1'>
                          Your Changes will now be reflected for all users.
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant='h5' gutterBottom>
                          Thank you for Posting this Church Information.
                        </Typography>
                        <Typography variant='subtitle1'>
                          Your Post is being reviewed and it will be published
                          when the information is verified.
                          <br />
                          {enviroment === "development" ? (
                            <a href='http://localhost:3000'> Back</a>
                          ) : (
                            <a href='http://eotchub.org'> Back</a>
                          )}
                        </Typography>
                      </>
                    )}
                  </React.Fragment>
                ) : (
                  <CircularProgress disableShrink />
                )
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Back
                      </Button>
                    )}
                    <Button
                      variant='contained'
                      color='primary'
                      disabled={validateInput(activeStep) && !state.accepted}
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {console.log({
                        active: validateInput(activeStep),
                        accepted: state.accepted,
                        disabled: validateInput(activeStep) || !state.accepted,
                      })}
                      {activeStep === steps.length - 1
                        ? isEdit
                          ? "Update"
                          : "Post Church"
                        : "Next"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </main>
      </React.Fragment>
    </React.Fragment>
  );
}
