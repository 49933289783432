import React, { useContext, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBars from "../Header/AppBar";
import LoginWithGoogle from "../Auth/LoginWithGoogle";
import UserContext from "../../context/auth-context";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

function SignIn(props) {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  // const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginUser] = useMutation(LOGIN_USER);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError([]);
    let errors = [];

    loginUser({ variables: { email, password } })
      .catch((res) => {
        setLoading(false);
        res.graphQLErrors.map((err) => errors.push(err.message));

        setError(errors);
        return;
      })
      .then((result) => {
        if (errors.length === 0) {
          setLoading(false);
          setError([]);
          localStorage.setItem("token", result?.data?.login?.token);
          user.login(result?.data?.login?.token);
          if (props.history) {
            props.history.push("/");
          } else {
            props.logedInClose(true);
          }
        }
      });
  };
  return (
    <React.Fragment>
      <AppBars />
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          {loading && <CircularProgress />}
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email Address'
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            {/* <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="phone"
                        label="Phone Number"
                        name="phone"
                        autoComplete="phone"
                        onChange={e => setPhone(e.target.value)}
                    /> */}
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={(e) => setPassword(e.target.value.trim())}
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            />
            <p style={{ color: "red" }}>{error}</p>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              Sign In
            </Button>

            <LoginWithGoogle />
            <Grid container>
              <Grid item xs>
                <Link to='/forgotPassword'> Forgot password?</Link>
              </Grid>
              <Grid item>
                <Button
                  color='primary'
                  onClick={() => {
                    history.push("/signup");
                    if (!props.history) {
                      props.close();
                    }
                  }}
                >
                  Don't have an account? Sign Up
                </Button>
                {/* <button onClick={() => props.history.push('/signup')}>Don't have an account? Sign Up</button> */}
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default SignIn;
