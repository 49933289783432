import React, { useState, useContext } from 'react';
import { useMutation, gql } from "@apollo/client"

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';



import UserContext from '../../context/auth-context'
import LogInWithGoogle from './LoginWithGoogle';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const SIGNUP_USER = gql`
mutation($email: String!,$password: String!){
    signUp(email:$email,password:$password){
    token
  }
}
`;

function SignUp(props) {

    const [email, setEmail] = useState("");

    // const [phone, setPhone] = useState("");

    const [error, setError] = useState([])

    const [password, setPassword] = useState("")

    const [passwordConfirm, setPasswordConfirm] = useState('')

    // const [signUpUser, { error: mutationError,data }] = useMutation(SIGNUP_USER);

    const [signUpUser] = useMutation(SIGNUP_USER);

    const user = useContext(UserContext)

    const handleSubmit = (e) => {
        e.preventDefault();

        setError([])
        let errors = []

        if (passwordConfirm !== password){
            setError(['passwords do not much'])
            return 
        }
        
        signUpUser({ variables: { email, password } })
       .catch(res => {

            res.graphQLErrors.map(err => errors.push(err.message))

            setError(errors)
            return
        }).then(result => {
            if (errors.length === 0) {
                setError([])
                localStorage.setItem('token', result.data.signUp.token)
                user.login(result.data.signUp.token, result.data.signUp.Userid)
                props.history.push('/')
            }
        }
        )

    }
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
        </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={email}
                                onChange={e => setEmail(e.target.value.trim())}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                label="Phone Number"
                                name="phone"
                                autoComplete="phone"
                                onChange={e => setPhone(e.target.value)}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={e => setPassword(e.target.value.trim())}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="confirm_password"
                                label="Confirm Password"
                                type="password"
                                id="confirm_password"
                                onChange={e => setPasswordConfirm(e.target.value)}
                            />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                            />
                        </Grid> */}
                    </Grid>
                    <p style={{ color: 'red' }}>{error}</p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign Up
          </Button>
                <LogInWithGoogle />
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Button color="primary" onClick={() => props.history.push('/signin')}>Already have an account? Sign in</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}

export default SignUp;