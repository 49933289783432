import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18next";

const enviroment = process.env.NODE_ENV;
let uri;

if (enviroment === "development") {
  uri = "http://localhost:4000";
} else {
  // uri = 'https://eotc-info-server-staging-ub7tml46pa-uc.a.run.app/'
  // uri = 'https://eotc-info-server.herokuapp.com/'
  uri = "https://eotc-info-api-prod-vfg5t2u4xa-ez.a.run.app";
}

const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  uri,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

console.log(process.env.NODE_ENV);
ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Suspense fallback={"Loading..."}>
        <App />
      </Suspense>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
