import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ChurchInfo.css";
import Image from "../../Images/church_icon.png";
import DirectionsRoundedIcon from "@material-ui/icons/DirectionsRounded";

function ChurchInfo({ church, props }) {
  const [imageSrc, setImageSrc] = useState(church.image.split(",")[0]);

  const handleImageError = () => {
    setImageSrc(Image);
  };
  let src = !church.image ? Image : church.image.split(",")[0];
  const renderTabots = () => {
    if (!church.additionalTabots) {
      return "";
    }
    return church.additionalTabots.map((each) => each.name).join(" | ");
  };
  return (
    <div className='church_list_info'>
      <div className='info_container'>
        <div className='header_info'>
          <div className='header_content'>
            <div className='church_image'>
              <Link
                className='church_name'
                to={`/church_details/${church?.id}`}
              >
                <img
                  src={imageSrc}
                  onError={handleImageError}
                  width='55'
                  height='55'
                  alt='profile_picture'
                />
              </Link>
            </div>
            <div className='church_address'>
              <Link className='church_name' to={`/church_details/${church.id}`}>
                <p>{`${church?.title} ${church?.name}`}</p>
              </Link>
              <p className='additional_tabot'>{renderTabots()}</p>
              <div className='direction_container'>
                <a
                  className='direction'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${church?.address?.street}+ ${church?.address?.city}+ ${church?.address?.state}+ ${church?.address?.country}+ ${church?.address?.zipcode}&travelmode=driving`}
                >
                  <DirectionsRoundedIcon />{" "}
                </a>
                <a
                  className='direction_item'
                  target='_blank'
                  rel='noreferrer'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${church?.address?.street}+ ${church?.address?.city}+ ${church?.address?.state}+ ${church?.address?.country}+ ${church?.address?.zipcode}&travelmode=driving`}
                >
                  {`${church?.address?.street}, ${church?.address?.city}, ${church?.address?.state}, ${church?.address?.country}, ${church?.address?.zipcode}`}
                </a>
              </div>
            </div>
          </div>
          <div>
            <Link className='next_page' to={`/church_details/${church?.id}`}>
              <i
                style={{ zIndex: 10 }}
                class='angle_right fas fa-angle-right fa-2x'
              ></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChurchInfo;
