import React from "react";
import "./ChurchInfoSide.css";
import { useTranslation } from "react-i18next";

function ChurchInfoSide() {
  const { t } = useTranslation();
  return (
    <div className='church_header'>
      <h2>{t("header.content")}</h2>
    </div>
  );
}

export default ChurchInfoSide;
