import React, { useContext, useState, useEffect } from "react";
import Search from "../Search/Search";
import "./Header.css";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import MyAccount from "./MyAccount";
import UserContext from "../../context/auth-context";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

const enviroment = process.env.NODE_ENV;

function Header({ searchChurch, detailHeader }) {
  const history = useHistory();
  const user = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem("i18nextLng"));

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
    setLang(event.target.value);
  };

  console.log("lang", lang);

  useEffect(() => {
    if (lang === "en-US") {
      setLang("en");
    }
  }, []);

  const navigateToPost = () => {
    if (localStorage.getItem("token")) {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      if (
        decodedToken.exp !== null &&
        Math.floor(new Date().getTime() / 1000.0) > decodedToken.exp
      ) {
        localStorage.removeItem("token");
        history.push("/signin");
        user.logout();
      } else {
        history.push("/post_church");
      }
    } else {
      history.push("/signin");
      user.logout();
    }
  };

  return (
    <div className='nav_bar'>
      <div className='menu'>
        <ul className='left_menu'>
          {enviroment === "development" ? (
            <h3 className='link'>
              <a href='http://localhost:3000'>{t("logo.text")}</a>
            </h3>
          ) : (
            <h3 className='link'>
              <a href='http://eotchub.org'>{t("logo.text")}</a>
            </h3>
          )}
          <Box style={{ marginLeft: "1em" }} sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <Select value={lang} onChange={handleChange}>
                <MenuItem value={"en"}>English</MenuItem>
                <MenuItem value={"am"}>አማርኛ</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </ul>
        <ul className='right_menu'>
          <li className='post_church' onClick={() => navigateToPost()}>
            {t("postChurch.1")}
          </li>
          <li className='account'>
            <MyAccount />
          </li>
        </ul>
      </div>
      <div className='search_header'>
        {!detailHeader ? <Search searchChurch={searchChurch} /> : detailHeader}
      </div>
    </div>
  );
}

export default Header;
