import React from "react";
import "./LeftSideDetail.css";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

function LeftSideDetail({ church }) {
  const { t, i18n } = useTranslation();
  const establishedDate = church?.establishedAt;
  const validDate = parseInt(establishedDate);
  let establishedAt;
  if (establishedDate != null) {
    establishedAt = format(validDate, "MM/dd/yyyy");
  }
  //   const establishedAt = validDate;
  return (
    <div className='church_info_side'>
      <ul>
        <li className='church_show_only'>
          <h5>
            {t("detail.1")}
            <ul className='list_detail'>
              {church?.additionalTabots?.length ? (
                church.additionalTabots.map((each, index) => (
                  <li key={index}>{each.name}</li>
                ))
              ) : (
                <li> No additional tabot</li>
              )}
            </ul>
          </h5>{" "}
        </li>
        <li className='church_show_only'>
          <h5>{t("detail.2")}</h5>
          <ul className='list_detail'>
            <li>{church?.contact?.phoneNumber}</li>
            <li>{church?.contact?.email}</li>
          </ul>
        </li>
        <li className='church_show_only'>
          <h5>{t("detail.3")}</h5>
          {church?.contact?.website === "" &&
          church?.contact?.facebook === "" &&
          church?.contact?.youtube === "" ? (
            <sm>
              <em>not available</em>
            </sm>
          ) : (
            <ul className='social_list_detail'>
              <li>
                <a
                  target='_blank'
                  href={church?.contact?.website}
                  rel='noreferrer'
                >
                  <i class='fa fa-globe fa' aria-hidden='true'></i>
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  href={church?.contact?.youtube}
                  rel='noreferrer'
                >
                  <i class='fa fa-youtube-play' aria-hidden='true'></i>
                </a>
              </li>
              <li>
                <a
                  target='_blank'
                  href={church?.contact?.facebook}
                  rel='noreferrer'
                >
                  <i class='fa fa-facebook' aria-hidden='true'></i>
                </a>
              </li>
            </ul>
          )}{" "}
        </li>
        {/* <li className="church_show_only">
          <h5> ADMIN</h5>
          <ul className="list_detail">
            <li>Aba XYZ</li>
          </ul>
        </li> */}
        <li className='church_show_only'>
          <h5>{t("detail.5")}</h5>
          <ul className='list_detail'>
            {church?.establishedAt === null || church?.establishedAt === "" ? (
              <sm>
                <em>not available</em>
              </sm>
            ) : (
              <li>Established: {establishedAt}</li>
            )}
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default LeftSideDetail;
