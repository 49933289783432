import React from "react";
import "./ChurchDetailAdd.css";
import DirectionsRoundedIcon from "@material-ui/icons/DirectionsRounded";

function ChurchInfo({ church, churchAddress }) {
  return (
    <div>
      <div className="header_address">
        <div className="cabab_icon">
          <a
            className="direction"
            target="_blank"
            href={`https://www.google.com/maps/dir/?api=1&destination=${church.address.street}+ ${church.address.city}+ ${church.address.state}+ ${church.address.country}+ ${church.address.zipcode}&travelmode=driving`}
            rel="noreferrer"
          >
            <DirectionsRoundedIcon />
          </a>
        </div>
        <div className="header_content">
          <a
            className="direction_item"
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps/dir/?api=1&destination=${church.address.street}+ ${church.address.city}+ ${church.address.state}+ ${church.address.country}+ ${church.address.zipcode}&travelmode=driving`}
          >
            {`${church.address.street}, ${church.address.city}, ${church.address.state}, ${church.address.country}, ${church.address.zipcode}`}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ChurchInfo;
