import { useState, useEffect } from "react";
import { City } from "country-state-city";
import countries from "../Search/countries.json";

export default function useCities(state) {
  console.log("stated", state);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (state?.country !== "") {
      return setStates(countries[state?.country]?.states);
    }
    setStates([]);
  }, [state?.country]);

  useEffect(() => {
    if (state?.state !== "" && state?.country !== "") {
      return setCities(
        // countries[props.state.country].states[props.state.state].cities
        City.getCitiesOfState(
          countries[state?.country]?.key,
          states[state?.state]?.abbreviation
        ).map((city) => {
          return city.name;
        })
      );
    }
    setCities([]);
  }, [state?.state, state?.country, states]);
  return { states, cities };
}
