import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { gql, useQuery, useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Title from "./Title";

const REMOVE_EVENT = gql`
  mutation ($eventId: Int!) {
    removeEvent(eventId: $eventId) {
      status
    }
  }
`;

const UPDATE_EVENT = gql`
  mutation (
    $eventId: Int!
    $status: String
    $title: String
    $start: String
    $end: String
  ) {
    updateEvent(
      eventId: $eventId
      status: $status
      title: $title
      start: $start
      end: $end
    ) {
      status
    }
  }
`;

const ADD_EVENT = gql`
  mutation addEventToCalender(
    $churchId: Int!
    $title: String!
    $start: String!
    $end: String!
  ) {
    addEvent(churchId: $churchId, title: $title, start: $start, end: $end) {
      title
    }
  }
`;

const ManageEvent = ({ church }) => {
  const [removeEvent] = useMutation(REMOVE_EVENT);
  const [updateEvent] = useMutation(UPDATE_EVENT);
  const [action, setAction] = useState("Actions");
  const [addEvent] = useMutation(ADD_EVENT);
  const [newEvent, setNewEvent] = useState({
    churchId: parseInt(church?.id),
    title: "",
    start: "",
    end: "",
  });
  const [editEvent, setEditEvent] = useState({
    churchId: parseInt(church?.id),
    eventId: "",
    title: "",
    start: "",
    end: "",
  });

  const [isEdit, setIsEdit] = useState(false);

  const handleAddEvent = () => {
    addEvent({
      variables: newEvent,
    }).then(() => {
      setNewEvent({
        churchId: parseInt(church.id),
        title: "",
        start: "",
        end: "",
      });
      eventRefetch();
      // Refetch the events after saving successfully.
    });
  };
  const handleEditEvent = () => {
    updateEvent({
      variables: {
        eventId: parseInt(editEvent.eventId),
        title: editEvent.title,
        start: editEvent.start,
        end: editEvent.end,
      },
    }).then(() => {
      setEditEvent({
        churchId: parseInt(church.id),
        eventId: "",
        title: "",
        start: "",
        end: "",
      });
      eventRefetch();
      setIsEdit(false);
      // Refetch the events after saving successfully.
    });
  };
  const {
    data: eventData,
    loading: loadingEvent,
    error: eventError,
    refetch: eventRefetch,
  } = useQuery(
    gql`
      query ($churchId: Int!) {
        getChurchEvents(churchId: $churchId) {
          id
          title
          start
          end
          status
        }
      }
    `,
    {
      variables: {
        churchId: parseInt(church?.id),
      },
    }
  );

  const handleAction = (e, id, title, start, end) => {
    if (e.target.value === "cancel" || e.target.value === "On Schedule") {
      if (
        window.confirm(
          `Are you sure you want to ${
            e.target.value === "On Schedule" ? "Schedule" : e.target.value
          } ` +
            title +
            "?"
        )
      ) {
        updateEvent({
          variables: { eventId: parseInt(id), status: e.target.value },
        }).then(() => {
          eventRefetch();
        });
      }
    } else if (e.target.value === "delete") {
      if (window.confirm("Are you sure you want to Delete " + title + "?")) {
        removeEvent({ variables: { eventId: parseInt(id) } }).then(() => {
          eventRefetch();
          setAction("Actions");
        });
      }
    } else if (e.target.value === "edit") {
      setIsEdit(true);
      setEditEvent({
        churchId: parseInt(church.id),
        eventId: parseInt(id),
        title,
        start: new Date(parseInt(start)),
        end: new Date(parseInt(end)),
      });
    }
  };

  return (
    <div>
      <Title>Events</Title>
      {!isEdit ? (
        <div>
          <h2>Add New Event</h2>
          <div>
            <div style={{ float: "left" }}>
              <lable>Title</lable>
              <input
                type="text"
                placeholder="Add Title"
                // style={{ width: "20%", marginRight: "10px" }}
                value={newEvent.title}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, title: e.target.value })
                }
              />
            </div>

            <div style={{ display: "flex", flex: 1 }}>
              <label style={{ whiteSpace: "nowrap" }}>Start Date</label>
              <DatePicker
                placeholderText="Start Date"
                style={{ marginRight: "10px" }}
                selected={newEvent.start}
                onChange={(start) => setNewEvent({ ...newEvent, start })}
              />
              <lable style={{ whiteSpace: "nowrap" }}>End Date</lable>
              <DatePicker
                placeholderText="End Date"
                selected={newEvent.end}
                onChange={(end) => setNewEvent({ ...newEvent, end })}
              />
              {/* End Date
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MM/dd/yyyy"
            placeholder="End Date MM/dd/yyyy"
            maxDate={new Date()}
            value={newEvent.end}
            onChange={(end) => setNewEvent({ ...newEvent, end })}
          />
        </MuiPickersUtilsProvider> */}
            </div>

            <button onClick={() => handleAddEvent()}>Add Event</button>
          </div>
        </div>
      ) : (
        <div>
          <h2>Edit Event</h2>
          <div>
            <div style={{ float: "left" }}>
              <lable>Title</lable>
              <input
                type="text"
                placeholder="Add Title"
                // style={{ width: "20%", marginRight: "10px" }}
                value={editEvent.title}
                onChange={(e) =>
                  setEditEvent({ ...editEvent, title: e.target.value })
                }
              />
            </div>

            <div style={{ display: "flex", flex: 1 }}>
              <label style={{ whiteSpace: "nowrap" }}>Start Date</label>
              <DatePicker
                placeholderText="Start Date"
                style={{ marginRight: "10px" }}
                selected={editEvent.start}
                onChange={(start) => setEditEvent({ ...editEvent, start })}
              />
              <lable style={{ whiteSpace: "nowrap" }}>End Date</lable>
              <DatePicker
                placeholderText="End Date"
                selected={editEvent.end}
                onChange={(end) => setEditEvent({ ...editEvent, end })}
              />
              {/* End Date
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MM/dd/yyyy"
            placeholder="End Date MM/dd/yyyy"
            maxDate={new Date()}
            value={newEvent.end}
            onChange={(end) => setNewEvent({ ...newEvent, end })}
          />
        </MuiPickersUtilsProvider> */}
            </div>

            <button onClick={() => handleEditEvent()}>Update Event</button>
            <button onClick={() => setIsEdit(false)}>close</button>
          </div>
        </div>
      )}
      {eventData?.getChurchEvents?.length > 0 && !isEdit && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Title</TableCell>
              <TableCell>start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventData?.getChurchEvents?.map((singleEvent) => {
              return (
                <TableRow id={singleEvent.id}>
                  <TableCell>*</TableCell>
                  <TableCell>{singleEvent.title}</TableCell>
                  <TableCell>
                    {new Date(parseInt(singleEvent.start)).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {new Date(parseInt(singleEvent.end)).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{singleEvent.status}</TableCell>
                  <TableCell>
                    <select
                      value={action}
                      onChange={(e) =>
                        handleAction(
                          e,
                          singleEvent.id,
                          singleEvent.title,
                          singleEvent.start,
                          singleEvent.end
                        )
                      }
                    >
                      <option value="default">Actions</option>
                      {singleEvent.status === "On Schedule" ? (
                        <option value="cancel">Cancel</option>
                      ) : (
                        <option value="On Schedule">On Schedule</option>
                      )}
                      <option value="edit">edit</option>
                      <option value="delete">Delete</option>
                    </select>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default ManageEvent;
