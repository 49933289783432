import React from "react";
import "./footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className='footer'>
      <div className='footer_top'>
        <div className='logo'>
          <h2>{t("logo.text")}</h2>
        </div>
        <div className='email'>
          <i class='far fa-envelope'></i>
          <p>mkit.support@eotcmk.org</p>
        </div>
        {/* <div className="social">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-facebook-f "></i>
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-twitter "></i>
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fab fa-youtube"></i>
          </a>
        </div> */}
      </div>
      <div className='copy_right'>
        <p>&copy; 2022 eotchub.org</p>
      </div>
    </div>
  );
}

export default Footer;
