import React from "react";
import Icon from "../Images/churchIcon.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
export default function MapDetail({ churchAddress, church }) {
  //Custom Icon
  const churchIcon = new L.Icon({
    iconUrl: Icon,
    iconSize: [60, 75], // size of the icon
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });
  return (
    <MapContainer
      center={[churchAddress.lat, churchAddress.lng]}
      zoom={13}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker
        position={[churchAddress.lat, churchAddress.lng]}
        icon={churchIcon}
        eventHandlers={{
          mouseover: (e) => {
            e.target.openPopup();
          },
          mouseout: (e) => {
            e.target.closePopup();
          },
          click: () => {
            window.open(
              `https://www.google.com/maps/dir/?api=1&destination=${church?.address?.street}+ ${church?.address?.city}+ ${church?.address?.state}+ ${church?.address?.country}+ ${church?.address?.zipcode}&travelmode=driving`
            );
          },
        }}
      >
        <Popup>
          {church.title} <br /> {church.name}
        </Popup>
      </Marker>
    </MapContainer>
  );
}
