import React from "react";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import countries from "../Search/countries.json";
import useCities from "./useCities";
import { useTranslation } from "react-i18next";

const Summary = ({ state, setActiveStep, setState }) => {
  const { cities } = useCities(state);
  const { t } = useTranslation();

  const renderTabots = (tabots) => {
    return tabots.map((tabot) => {
      return <li key={tabot}>{tabot}</li>;
    });
  };

  const addressNames = {
    ...state,
    country: countries[state.country].key,
    state: countries[state.country].states[state.state].abbreviation,
    city: cities[state.city],
  };

  return (
    <div>
      <h3>{t("summary.summaryHeader")}</h3>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "30px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <p>
            {" "}
            <strong>{t("churchInfo.title")}:</strong> {state.title}
          </p>
          <strong>{t("churchInfo.name")}:</strong> {state.name}
          <p>
            {" "}
            <strong> {t("churchInfo.establishedAt")}: </strong>
            {state.establishedAt
              ? state.establishedAt.toLocaleDateString()
              : "Not set"}{" "}
          </p>
          <p>
            {" "}
            <strong>{t("churchInfo.description")}:</strong>
            {state.description}{" "}
          </p>
          <p>
            {" "}
            <strong> {t("churchInfo.hasAdditionalTabot")}:</strong>
            {state.additionalTabots?.length === 0
              ? " No"
              : renderTabots(state.additionalTabots)}
          </p>
        </div>
        <div>
          {/* <button onClick={() => setActiveStep(0)}>change</button> */}
          <i
            style={{ cursor: "pointer" }}
            onClick={() => setActiveStep(0)}
            className='fas fa-pen'
          ></i>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "30px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <p>
            <strong> {t("address.country")}: </strong> {addressNames.country}{" "}
          </p>
          <p>
            {" "}
            <strong>{t("address.state")}: </strong> {addressNames.state}{" "}
          </p>
          <p>
            <strong>{t("address.city")}:</strong> {addressNames.city}{" "}
          </p>
          <p>
            <strong>{t("address.street")}:</strong> {state.street}{" "}
          </p>
          <p>
            <strong>{t("address.zipCode")}:</strong> {state.zipcode}{" "}
          </p>
        </div>
        <div>
          {/* <button onClick={() => setActiveStep(1)}>change</button> */}
          <i
            style={{ cursor: "pointer" }}
            onClick={() => setActiveStep(1)}
            className='fas fa-pen'
          ></i>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "30px",
        }}
      >
        <div style={{ textAlign: "left" }}>
          <p>
            <strong>{t("contact.email")}:</strong> {state.email}{" "}
          </p>
          <p>
            <strong>{t("contact.phoneNumber")}:</strong> {state.phoneNumber}{" "}
          </p>
          <p>
            <strong>{t("social.youTube")}:</strong> {state.Youtube}
          </p>
          <p>
            <strong>{t("social.facebook")}:</strong> {state.facebook}
          </p>
          <p>
            <strong>{t("social.website")}:</strong> {state.Website}
          </p>
        </div>
        <div>
          {/* <button onClick={() => setActiveStep(2)}>change</button> */}
          <i
            style={{ cursor: "pointer" }}
            onClick={() => setActiveStep(2)}
            className='fas fa-pen'
          ></i>
        </div>
      </div>
      <Divider />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.accepted}
            onChange={() => setState({ ...state, accepted: !state.accepted })}
            name='checkedB'
            color='primary'
          />
        }
        label={t("summary.disclaimer")}
      />
    </div>
  );
};

export default Summary;
