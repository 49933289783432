import React, { useState, useEffect } from "react";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";

import Title from "./Title";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const ManagePermission = ({ church }) => {
  const {
    data: contactData,
    loading: loadingContact,
    error: contactError,
  } = useQuery(
    gql`
      query ($churchId: Int) {
        getChurchContact(churchId: $churchId) {
          id
          role
          email
        }
      }
    `,
    {
      variables: {
        churchId: parseInt(church?.id),
      },
    }
  );

  const {
    data: assignedData,
    loading: loadingAssigned,
    error: assignedError,
    refetch: refetchAssigned,
  } = useQuery(
    gql`
      query ($churchId: Int) {
        getChurchAssignedAdmins(churchId: $churchId) {
          id
          role
          email
        }
      }
    `,
    {
      variables: {
        churchId: parseInt(church?.id),
      },
    }
  );

  const [user, setUser] = useState({});
  const [isEntry, setIsEntry] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [option, setOption] = useState([]);

  const [addAdmin] = useMutation(gql`
    mutation ($church_id: Int, $user_id: Int) {
      assignChurchAdmin(church_id: $church_id, user_id: $user_id) {
        church_id
      }
    }
  `);

  const [removeAdmin] = useMutation(gql`
    mutation ($church_id: Int, $user_id: Int) {
      removeAssignChurchAdmin(church_id: $church_id, user_id: $user_id) {
        church_id
      }
    }
  `);

  const classes = useStyles();

  const handleAddAdmin = () => {
    addAdmin({
      variables: {
        church_id: parseInt(church.id),
        user_id: parseInt(user.id),
      },
    })
      .catch((error) => {
        console.log(error);
      })
      .then((res) => {
        refetchAssigned();
        setIsEntry(false);
        setUser({});
      });
  };

  const handleInvite = (inputValue) => {
    alert(`Invite ${email} ?`);
    setEmail("");
  };

  const handleRemoveAdmin = (user_id) => {
    if (window.confirm("Are you sure you want to remove this Admin?")) {
      removeAdmin({
        variables: {
          church_id: parseInt(church.id),
          user_id: parseInt(user_id),
        },
      })
        .catch((error) => {
          console.log(error);
        })
        .then((res) => {
          refetchAssigned();
        });
    } else {
      // Do nothing!
      console.log("Thing was not saved to the database.");
    }
  };

  const [getEmails, { data, error, loading }] = useLazyQuery(
    gql`
      query ($email: String) {
        getUsersDropdownList(email: $email) {
          id
          role
          email
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );
  const [needInvite, setNeedInvite] = useState(false);
  const emailRegX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    // if (inputValue.length > 13 && inputValue.includes("@")) {
    if (emailRegX.test(inputValue)) {
      getEmails({
        variables: {
          email: inputValue,
        },
      });
      setNeedInvite(true);
    }
  }, [inputValue]);

  useEffect(() => {
    if (data) {
      data && setOption(data?.getUsersDropdownList);
    }
    data && setOption(data?.getUsersDropdownList);
  }, [data]);
  const [email, setEmail] = useState("");
  return (
    <div>
      <Title>Church Admins</Title>
      {!isEntry ? (
        <button onClick={() => setIsEntry(true)}>Assign Admin</button>
      ) : (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Autocomplete
                value={user}
                onChange={(e, newValue) => {
                  setUser(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                  setInputValue(newInputValue);
                  if (newInputValue) {
                    setEmail(newInputValue);
                  }
                }}
                id="controllable-states-demo"
                options={option}
                getOptionLabel={(option) => option.email}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Email" variant="outlined" />
                )}
              />
            </div>
            {needInvite && data?.getUsersDropdownList?.length === 0 && (
              <button
                type="submit"
                style={{ padding: "15px" }}
                onClick={() => handleInvite(inputValue)}
              >
                Invite
              </button>
            )}
          </div>
          <div>
            <button
              onClick={() => {
                handleAddAdmin();
              }}
              disabled={!user?.email}
            >
              Add
            </button>
          </div>
        </div>
      )}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Admin Email</TableCell>
            <TableCell>Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>*</TableCell>
            <TableCell>{church?.admin?.email}</TableCell>
            <TableCell>Creator</TableCell>
            <TableCell>
              <button>Replace</button>
            </TableCell>
          </TableRow>
          {contactData && (
            <TableRow>
              <TableCell>*</TableCell>
              <TableCell>{contactData?.getChurchContact?.email}</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>
                <button>Replace</button>
              </TableCell>
            </TableRow>
          )}
          {assignedData &&
            assignedData?.getChurchAssignedAdmins?.map((usr) => {
              return (
                <TableRow key={usr.id}>
                  <TableCell>*</TableCell>
                  <TableCell>{usr?.email}</TableCell>
                  <TableCell>Assigned Admin</TableCell>
                  <TableCell>
                    <button
                      onClick={() => {
                        handleRemoveAdmin(usr.id);
                      }}
                    >
                      Remove
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManagePermission;
