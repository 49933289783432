import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Church from "./components/Church/Church";
import Detail from "./components/Detail/Detail";
import Signin from "./components/Auth/Signin";
import Signup from "./components/Auth/Signup";
import PostChruch from "./components/PostChurch/PostChruch";
import ChangePassword from "./components/Auth/ChangePassword";
import PasswordReset from "./components/Auth/PasswordReset";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFound from "./components/NotFound";
import { UserProvider } from "./context/auth-context";

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));

  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    //  Check if user has logged in and remove the token if the expire date has lapsed.
    if (localStorage.getItem("token")) {
      const decodedToken = jwtDecode(localStorage.getItem("token"));
      setUserDetails(decodedToken);
      if (
        decodedToken.exp !== null &&
        Math.floor(new Date().getTime() / 1000.0) > decodedToken.exp
      ) {
        localStorage.removeItem("token");
        setToken("");
        logout();
      }
    }
  }, []);

  const login = (access_token) => {
    setToken(access_token);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: rest.location.pathname },
              }}
            />
          )
        }
      />
    );
  };

  const PublicRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (token) {
            if (
              rest.location.pathname === "/signin" ||
              rest.location.pathname === "/signup"
            ) {
              return <Redirect to="/" />;
            }
          }
          return !token && rest.location.pathname === "/changepassword" ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          );
        }}
      />
    );
  };

  return (
    <div className="App">
      <Router>
        <UserProvider value={{ token, login, logout, userDetails }}>
          <Switch>
            <PublicRoute path="/" exact component={Church} />
            <PublicRoute path="/church_details/:id" component={Detail} />
            <PublicRoute path="/signin" component={Signin} />
            <PublicRoute path="/signup" component={Signup} />
            <PublicRoute path="/changepassword" component={ChangePassword} />
            <PublicRoute path="/passwordReset" component={PasswordReset} />
            <PublicRoute path="/forgotPassword" component={ForgotPassword} />
            <ProtectedRoute path="/post_church" component={PostChruch} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <Route component={NotFound} />
          </Switch>
        </UserProvider>
      </Router>
    </div>
  );
};

export default App;
