import React, { useState, useEffect, useContext } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import UserContext from "../../context/auth-context";

const GOOGLE_LOGIN = gql`
  mutation ($token: String!) {
    loginWithGoogle(token: $token) {
      email
      token
    }
  }
`;

const LogInWithGoogle = () => {
  const [isLogined, setIsLogined] = useState(false);
  const [googleSignin] = useMutation(GOOGLE_LOGIN);

  const history = useHistory();

  const user = useContext(UserContext);

  const [error, setError] = useState([]);

  const [loading, setLoading] = useState(false);

  const login = (response) => {
    if (response.accessToken) {
      setLoading(true);
      setError([]);
      let errors = [];
      setIsLogined(true);
      googleSignin({
        variables: {
          token: response.tokenId,
        },
      })
        .catch((res) => {
          setLoading(false);
          res.graphQLErrors.map((err) => errors.push(err.message));

          setError(errors);
          return;
        })
        .then((result) => {
          if (errors.length === 0) {
            setLoading(false);
            setError([]);
            localStorage.setItem("token", result.data.loginWithGoogle.token);
            user.login(result.data.loginWithGoogle.token);
            history.push("/");
          }
        });
    }
    console.log({ response });
  };

  const logout = (response) => {
    setIsLogined(false);
    localStorage.removeItem("token");
  };

  const handleLoginFailure = (response) => {
    alert("Failed to log in");
  };

  const handleLogoutFailure = (response) => {
    alert("Failed to log out");
  };

  function locationError(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLogined(true);
    }
    navigator.geolocation?.getCurrentPosition(
      function (position) {
        localStorage.setItem("currentLat", position.coords.latitude);
        localStorage.setItem("currentLng", position.coords.longitude);
      },
      locationError,
      { enableHighAccuracy: true }
    );
  }, []);

  return (
    <div>
      {!isLogined ? (
        <GoogleLogin
          clientId="336788008902-b95b1lmhtra584jv8m2enh9hrtrug4hh.apps.googleusercontent.com"
          buttonText="Login with Google"
          onSuccess={login}
          // isSignedIn={true}
          onFailure={handleLoginFailure}
          cookiePolicy={"single_host_origin"}
        />
      ) : (
        <div>
          <GoogleLogout
            clientId="336788008902-b95b1lmhtra584jv8m2enh9hrtrug4hh.apps.googleusercontent.com"
            buttonText="Logout"
            onLogoutSuccess={logout}
            onFailure={handleLogoutFailure}
          ></GoogleLogout>
          {/* <GoogleLogout
      clientId="725388956322-oemvevgiqhl8q5pnufhv7pmgj98se149.apps.googleusercontent.com"
      buttonText='Logout'
      onLogoutSuccess={ logout }
      onFailure={ handleLogoutFailure }
    ></GoogleLogout> */}
        </div>
      )}
    </div>
  );
};

export default LogInWithGoogle;
