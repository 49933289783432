import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "./Title";
import Image from "../../Images/church.png";
import EditChurchModal from "./EditChurchModal";

import { gql, useQuery, useMutation } from "@apollo/client";

import { useHistory } from "react-router";
import ManagePermissionModel from "./ManagePermissionModal";
import ManageEventModel from "./ManageEventModal";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const MY_CHURCHES = gql`
  query ($userId: Int) {
    myChurches(userId: $userId) {
      id
      name
      establishedAt
      title
      image
      admin {
        email
      }
      status
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation ($churchId: Int, $status: String) {
    updateChurchStatus(churchId: $churchId, status: $status) {
      name
      status
    }
  }
`;

export default function MyChurches({ user }) {
  const classes = useStyles();
  const history = useHistory();

  const { data, error, refetch } = useQuery(MY_CHURCHES, {
    variables: {
      userId: user?.userDetails?.userId,
    },
  });

  const [updateStat] = useMutation(UPDATE_STATUS);

  const handleDisable = (id) => {
    updateStat({
      variables: { churchId: parseInt(id), status: "Disabled" },
    }).then((res) => {
      console.log({ res });
      refetch();
    });
  };

  const handleApprove = (id) => {
    updateStat({
      variables: { churchId: parseInt(id), status: "Approved" },
    }).then((res) => {
      console.log({ res });
      refetch();
    });
  };
  return (
    <React.Fragment>
      <Title>My Churches</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Church ID</TableCell>
            <TableCell>Established at</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Admin Email</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.myChurches?.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <img
                  alt="church profile"
                  src={row.image ? row.image?.split(",")[0] : Image}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                    border: "3px solid #3f51b5",
                  }}
                />
              </TableCell>
              <TableCell>{row.id}</TableCell>
              <TableCell>{`${new Date(
                Number(row.establishedAt)
              ).toLocaleDateString()}`}</TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.admin?.email}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>
                <EditChurchModal id={row.id} />
              </TableCell>
              {user.userDetails.role === "MKAdmin" && (
                <>
                  {row.status === "Approved" ? (
                    <TableCell>
                      <button onClick={() => handleDisable(row.id)}>
                        Disable
                      </button>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <button onClick={() => handleApprove(row.id)}>
                        Approve
                      </button>
                    </TableCell>
                  )}
                </>
              )}
              <TableCell>
                <ManagePermissionModel church={row} />
              </TableCell>
              <TableCell>
                <ManageEventModel church={row} />
              </TableCell>
              <TableCell
                align="right"
                onClick={() => history.push(`/church_details/${row.id}`)}
              >
                <Link className="next_page" to={`/church_details/${row.id}`}>
                  <i class="angle_right fas fa-angle-right fa-2x"></i>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more Churches
        </Link>
      </div>
    </React.Fragment>
  );
}
