const is_email = (email) => {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(String(email).toLowerCase())) {
    return true;
  } else {
    return false;
  }
};
const is_phoneNumber = (phoneNumber) => {
  const reg =
    /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
  if (reg.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};

const is_youTube = (youTubeUrl) => {
  const reg =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g;
  if (reg.test(youTubeUrl)) {
    return true;
  } else {
    return false;
  }
};
const is_facebook = (faceBookUrl) => {
  const reg =
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/g;
  if (reg.test(faceBookUrl)) {
    return true;
  } else {
    return false;
  }
};

const is_validURL = (urlString) => {
  const reg =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
  if (reg.test(urlString)) {
    return true;
  } else {
    return false;
  }
};

const validateName = (name) => {
  const regex = new RegExp(/[^0-9]/, "g");
  if (regex.test(name)) {
    return true;
  } else {
    return false;
  }
};

export {
  is_email,
  is_phoneNumber,
  is_facebook,
  is_youTube,
  is_validURL,
  validateName,
};
