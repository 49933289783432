import React, { useState, useEffect } from "react";
import "./Search.css";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import countries from "./countries.json";
import { City } from "country-state-city";
import { useTranslation } from "react-i18next";

function Search({searchChurch }) {
  const { t } = useTranslation();
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const [currentQuery, setCurrentQuery] = useState("");
  const [currentCountry, setCurrentCountry] = useState("");
  const [currentState, setCurrentState] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const onChange = ({ target }) => {
    if (target.name === "country") {
      setCurrentCountry(target.value);
      setCurrentState("");
      setCurrentCity("");
      if (target.value !== "") {
        setStates(countries[target.value].states);
      }
    }
    if (target.name === "state") {
      setCurrentState(target.value);
      setCurrentCity("");
      if (target.value !== "") {
        const cities = City.getCitiesOfState(
          countries[currentCountry].key,
          states[target.value].abbreviation
        );
        setCities(
          cities.map((city) => {
            return city.name;
          })
        );
      }
    }
    if (target.name === "city") {
      setCurrentCity(target.value);
    }
  };

  const onQueryChange = (event) => {
    setCurrentQuery(event.target.value);
  };

  const keyPress = (e) => {
    if (e.key === "Enter") {
      filter();
    }
  };

  const filter = () => {
    searchChurch({
      country: currentCountry !== "" ? countries[currentCountry].key : null,
      state: currentState !== "" ? states[currentState].abbreviation : null,
      city: currentCity !== "" ? cities[currentCity].name : null,
      name: currentQuery || null,
    });
  };

  useEffect(() => {
    filter();
  }, [currentCountry, currentState, currentCity]);

  const isButtonDisabled = () => {
    return (
      currentCountry === "" &&
      currentState === "" &&
      currentCity === "" &&
      currentQuery === ""
    );
  };

  return (
    <div className='search'>
      <Grid container spacing={2}>
        <Grid item>
          <FormControl className='formControl'>
            <InputLabel id='demo-simple-select-label'>
              {t("address.country")}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='filter-by'
              name='country'
              value={currentCountry}
              onChange={onChange}
              label='Search By'
              className={classes.formControl}
            >
              <MenuItem value=''>--{t("select.1")}--</MenuItem>
              {countries.map((country, index) => (
                <MenuItem key={index} value={index}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className='formControl'>
            <InputLabel id='demo-simple-select-label'>
              {t("address.state")}
            </InputLabel>
            <Select
              labelId='demo-customized-select-label'
              id='filter-by'
              name='state'
              value={currentState}
              onChange={onChange}
              label='Search By'
              className={classes.formControl}
            >
              <MenuItem value=''>--{t("select.2")}--</MenuItem>
              {states.map((state, index) => (
                <MenuItem key={index} value={index}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className='formControl'>
            <InputLabel id='demo-simple-select-label'>
              {t("address.city")}
            </InputLabel>
            <Select
              labelId='demo-customized-select-label'
              id='filter-by'
              name='city'
              value={currentCity}
              onChange={onChange}
              label='Search By'
              className={classes.formControl}
            >
              <MenuItem value=''>--{t("select.3")}--</MenuItem>
              {cities.map((city, index) => (
                <MenuItem key={index} value={index}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <input
            className='church_input'
            type='text'
            onChange={onQueryChange}
            placeholder={t("placeholder.1")}
            onKeyPress={keyPress}
          />
          <button
            className='search_button'
            disabled={isButtonDisabled()}
            onClick={filter}
          >
            <i class='fas fa-search'></i>
          </button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Search;
