import React, { useState, useEffect } from "react";
import Icon from "../Images/churchIcon.png";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useQuery, gql } from "@apollo/client";

export default function MapDetail({ markers }) {
  const { data } = useQuery(
    gql`
      query allChurches($query: queryType) {
        churches(query: $query) {
          rows {
            name
            title
            address {
              street
              city
              state
              zipcode
              country
              lat
              lng
              hasLocation
            }
          }
        }
      }
    `,
    {
      variables: {
        query: {},
      },
      notifyOnNetworkStatusChange: true,
    }
  );
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      setSearchResult(data?.churches?.rows);
    }
  }, [data]);

  // Custom Icon
  const churchIcon = new L.Icon({
    iconUrl: Icon,
    iconSize: [60, 75], // size of the icon
    iconAnchor: [30, 65], // point of the icon which will correspond to marker's location
    shadowAnchor: [4, 62], // the same for the shadow
    popupAnchor: [-3, -66], // point from which the popup should open relative to the iconAnchor
  });

  const getMarkers = () => {
    let markes = [];
    searchResult.map((result) => {
      if (result.address.lat !== null && result.address.lng !== null) {
        markes.push({
          lat: result.address.lat,
          lng: result.address.lng,
          church: result,
        });
      }
    });
    return markes;
  };

  const markerstoRender = searchResult.length === 0 ? markers : getMarkers();

  return (
    <MapContainer
      center={[39.38, -97.92]}
      zoom={4}
      scrollWheelZoom={true}
      attributionControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {markerstoRender?.map((mark) => {
        return (
          <Marker
            key={mark.lat}
            position={[mark.lat, mark.lng]}
            icon={churchIcon}
            eventHandlers={{
              mouseover: (e) => {
                e.target.openPopup();
              },
              mouseout: (e) => {
                e.target.closePopup();
              },
              click: () => {
                window.open(
                  `https://www.google.com/maps/dir/?api=1&destination=${mark?.church?.address?.street}+ ${mark?.church?.address?.city}+ ${mark?.church?.address?.state}+ ${mark?.church?.address?.country}+ ${mark.church?.address?.zipcode}&travelmode=driving`
                );
              },
            }}
          >
            <Popup>
              {mark.church?.title} <br /> {mark.church?.name}.
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
}
