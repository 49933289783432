import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {useHistory} from 'react-router-dom'

import { gql,useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const RESET_PASSWORD = gql`
 mutation($password: String,$resetToken: String){
  resetPassword(password:$password,resetToken:$resetToken){
    resetToken
  }
}
`;

const PasswordReset = (props) => {
    const [password, setPassword] = useState("")
    const [comfirmPassword, setComfirmPassword] = useState("")
    const history = useHistory()
    const [resetPassword] = useMutation(RESET_PASSWORD)

    const [error, setError] = useState([])

    const [isSuccess, setIsSuccess] = useState(false)

    const changePassword = (e) => {
        e.preventDefault()
        setError([])
        let errors = []

        if (password !== comfirmPassword) {
            setError(errors.push("Passwords don't match"))
        }
        if (password.length < 6) {
            setError(errors.push("Password is too short"))
        }
        if (errors.length === 0) {
            resetPassword({
                variables: {
                    password,
                    resetToken: props.location.search.split('?')[1]
                }
            }).catch(res => {
                res.graphQLErrors.map(err => errors.push(err.message))

                setError(errors)
                return
            }).then(() => {
                if (errors.length === 0) {
                    setError([])
                    setIsSuccess(true)
                    // props.history.push('/')
                }
            })
        } else {
            setError(errors)

        }
    }
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            {!isSuccess?<div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Change Password
        </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}

                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="comfirmpassword"
                        label="comfirmPassword"
                        type="password"
                        id="comfirmpassword"
                        autoComplete="comfirm-password"
                        value={comfirmPassword}
                        onChange={(e) => setComfirmPassword(e.target.value)}

                    />
                    <p style={{ color: 'red' }}>{error}</p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(e) => changePassword(e)}
                    >
                        Set Password
          </Button>
                </form>
            </div>: 
            <div style={{marginTop: "100px"}}>
                Password Successfully Changed. 
                <br />
                <Link  onClick={() => history.push('/')}>
                        Go To Home
                </Link>
            </div>
            }
        </Container>
    );
}

export default PasswordReset