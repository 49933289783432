import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import { gql, useMutation } from "@apollo/client";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";

const ADD_EVENT = gql`
  mutation addEventToCalender(
    $churchId: Int!
    $title: String!
    $start: String!
    $end: String!
  ) {
    addEvent(churchId: $churchId, title: $title, start: $start, end: $end) {
      title
    }
  }
`;
const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function EventCalender({ events, churchID, user, refetch }) {
  const [addEvent] = useMutation(ADD_EVENT);
  const [newEvent, setNewEvent] = useState({
    churchId: parseInt(churchID),
    title: "",
    start: "",
    end: "",
  });

  const handleAddEvent = () => {
    addEvent({
      variables: newEvent,
    }).then(() => {
      setNewEvent({
        churchId: parseInt(churchID),
        title: "",
        start: "",
        end: "",
      });

      // Refetch the events after saving successfully.
      refetch();
    });
  };
  const localizeEvent = (events) => {
    const eventsReady = [];
    events.map((event) => {
      eventsReady.push({
        ...event,
        start: new Date(parseInt(event.start)),
        end: new Date(parseInt(event.end)),
      });
    });
    return eventsReady;
  };

  return (
    <div>
      {user.userDetails.role === "MKAdmin" && (
        <div>
          <h2>Add New Event</h2>
          <div>
            <div style={{ float: "left" }}>
              <lable>Title</lable>
              <input
                type="text"
                placeholder="Add Title"
                // style={{ width: "20%", marginRight: "10px" }}
                value={newEvent.title}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, title: e.target.value })
                }
              />
            </div>

            <div style={{ display: "flex", flex: 1 }}>
              <label style={{ whiteSpace: "nowrap" }}>Start Date</label>
              <DatePicker
                placeholderText="Start Date"
                style={{ marginRight: "10px" }}
                selected={newEvent.start}
                onChange={(start) => setNewEvent({ ...newEvent, start })}
              />
              <lable style={{ whiteSpace: "nowrap" }}>End Date</lable>
              <DatePicker
                placeholderText="End Date"
                selected={newEvent.end}
                onChange={(end) => setNewEvent({ ...newEvent, end })}
              />
              {/* End Date
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="MM/dd/yyyy"
            placeholder="End Date MM/dd/yyyy"
            maxDate={new Date()}
            value={newEvent.end}
            onChange={(end) => setNewEvent({ ...newEvent, end })}
          />
        </MuiPickersUtilsProvider> */}
            </div>

            <button onClick={() => handleAddEvent()}>Add Event</button>
          </div>
        </div>
      )}
      <h2>Events</h2>
      {events.length === 0 ? (
        <div>No Events</div>
      ) : (
        <Calendar
          localizer={localizer}
          events={localizeEvent(events)}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500, margin: "50px" }}
        />
      )}
    </div>
  );
}

export default EventCalender;
