import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { gql,useMutation } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


const REQUEST_RESET_LINK = gql`
mutation($email: String){
  requestReset(email:$email){
    email
    resetToken
  }
}
`;

const ForgotPassword = (props) => {
    const [email, setEmail] = useState("")
    const [notifiy, setNotifiy] = useState(false)
    const [error, setError] = useState([])
    const emailPattern = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const [requestResetLink] = useMutation(REQUEST_RESET_LINK)
    
    const requestLink = e => {
        e.preventDefault()
        setError([])
        let errors = []

        if (email === "") {
            setError(errors.push("please enter an email"))
        }
        if (email !== "") {
            if (!email.match(emailPattern)) {
                setError(errors.push("invalid email"))
            }
        }
        if (errors.length === 0) {
            requestResetLink({
                variables: {
                    email
                }
            }).catch(res => {
                res.graphQLErrors.map(err => errors.push(err.message))

                setError(errors)
                return
            }).then(() => {
                if (errors.length === 0) {
                    setError([])
                    setNotifiy(true)
                }

            })
        } else {
            setError(errors)
        }
    }
    const classes = useStyles();

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Password Reset Request
        </Typography>
                {!notifiy ? <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <p style={{ color: 'red' }}>{error}</p>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={e => requestLink(e)}
                    >
                        Send Me A Reset Link
          </Button>
                </form> :
                    <p>Reset link sent, please check your Email</p>}
            </div>
        </Container>
    );
}

export default ForgotPassword