import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import "./postChurch.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import cyan from "@material-ui/core/colors/cyan";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  textFeild: {
    fontSize: "16px !important",
    lineHeight: "1px",
    borderRadius: "20px",
    marginBottom: "15px",
  },
  picker: {
    border: "1px solid rgba(0,0,0,.2) !important",
    padding: "17px 17px",
    margin: "0 0 15px  !important",
    borderRadius: "5px",
    width: "100%",
    outliine: "1px solid grey",

    "&.MuiInputBase-input": {
      border: "none",
    },
  },
  chechLabel: {
    alignItems: "center",
    marginBottom: "10px",
  },
  input: {
    display: "none",
  },
  description: {
    height: "100px !important",
    marginTop: "10px",
  },
  grid_left: {
    borderRight: "2px solid lightgrey",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  label: {
    float: "left",
    paddingBlockEnd: "2px",
  },
  church_label: {
    textAlign: "left !important",
    color: "black",
  },
  additional_tabot: {
    marginBottom: "10px",
    textAlign: "left",
  },
  redStar: {
    color: "red",
  },
  required: {
    color: "red",
    float: "left !important",
  },
}));

const defaultMaterialTheme = createTheme({
  palette: {
    primary: cyan,
  },
});

export default function AddressForm(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [touched, settouched] = useState(false);
  const { t } = useTranslation();

  const img = props?.state?.image?.split("+space+");
  const handleTouch = () => {
    settouched(true);
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
    if (checked) {
      props.state.additionalTabots.length = 0;
    }
  };

  useEffect(() => {
    if (props?.state?.additionalTabots?.length > 0) {
      setChecked(true);
    }
  }, [props.state]);

  const validateName = (name) => {
    const regex = new RegExp(/[^0-9]/, "g");
    if (regex.test(name)) {
      return true;
    } else {
      return false;
    }
  };

  function defaultRenderLayout(tagComponents, inputComponent) {
    return (
      <div>
        <div className='inputComponent'>{inputComponent}</div>
        <div>{tagComponents}</div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} spacing={3} className={classes.grid_left}>
          <Grid container>
            <Typography>
              {t("churchInfo.title")} <span className={classes.redStar}>*</span>
            </Typography>
            <TextField
              required
              id='title'
              name='title'
              className={classes.textFeild}
              onBlur={handleTouch}
              placeholder='e.g. Debre Selam'
              variant='outlined'
              fullWidth
              value={props.state.title}
              onChange={(e) => props.handleChange(e)}
            />
            {touched && props.state.title.length === 0 ? (
              <p className={classes.required}>required</p>
            ) : touched && !validateName(props.state.title) ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>

          <Grid container>
            <Typography>
              {t("churchInfo.name")} <span className={classes.redStar}>*</span>
            </Typography>
            <TextField
              required
              id='name'
              name='name'
              placeholder='e.g. Saint John the Baptist'
              className={classes.textFeild}
              variant='outlined'
              fullWidth
              value={props.state.name}
              onChange={(e) => props.handleChange(e)}
            />

            {touched && props.state.name.length === 0 ? (
              <p className={classes.required}>required</p>
            ) : touched && !validateName(props.state.name) ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item>
            <Typography align='left'>
              {t("churchInfo.establishedAt")}
            </Typography>
            <Grid
              container
              alignContent='flex-start'
              className='established_at'
            >
              <ThemeProvider
                theme={defaultMaterialTheme}
                className='established_at'
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    inputVariant='outlined'
                    format='MM/dd/yyyy'
                    placeholder='MM/dd/yyyy'
                    maxDate={new Date()}
                    value={props.state.establishedAt}
                    onChange={(value) =>
                      props.handleChange({
                        target: {
                          name: "establishedAt",
                          value,
                        },
                      })
                    }
                  />
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Typography>{t("churchInfo.description")}</Typography>
            <TextField
              required
              id='description'
              name='description'
              placeholder='A short description about the church'
              variant='outlined'
              rows={3}
              rowsMax={10}
              multiline
              fullWidth
              value={props.state.description}
              onChange={(e) => props.handleChange(e)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} spacing={1} className='additional_tabot'>
          <Grid item spacing={3}>
            <FormControlLabel
              className={classes.chechLabel}
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => handleChecked(e)}
                  name='checkedB'
                  color='primary'
                />
              }
              label={t("churchInfo.hasAdditionalTabot")}
            />
            {checked && (
              <Grid item>
                <Grid item className='additional_tabot'>
                  <Typography align='left'>Tabot Name</Typography>
                  <TagsInput
                    value={props?.state?.additionalTabots}
                    className='my-tags-input'
                    renderLayout={defaultRenderLayout}
                    onChange={(value) =>
                      props.handleChange({
                        target: {
                          name: "additionalTabots",
                          value,
                        },
                      })
                    }
                    inputProps={{
                      ...props,
                      placeholder: "Press enter to add new tabot",
                    }}
                    name='additional-tabot-name'
                    onlyUnique={true}
                    inputValue={props?.state["additional-tabot-name"]}
                    addOnBlur={true}
                  />
                </Grid>
                <input type='button' value='Add another tabot' />
              </Grid>
            )}
          </Grid>

          {/* ----- IMAGE UPLOAD ---------------- */}
          <Grid item>
            <CardContent>
              <Grid container justify='flex-start' alignItems='flex-start'>
                <input
                  accept='image/*'
                  name='image'
                  className={classes.input}
                  id='contained-button-file'
                  multiple
                  type='file'
                  onChange={(e) => props.handleChange(e)}
                />
                <Grid item>
                  <Typography>{t("churchInfo.uploadImage")}</Typography>
                  <Grid container>
                    <label htmlFor='contained-button-file'>
                      <Fab component='span' className={classes.button}>
                        <AddPhotoAlternateIcon />
                      </Fab>
                    </label>
                  </Grid>
                </Grid>
              </Grid>
              {img?.length !== 0
                ? img?.map((imageList) => {
                    return (
                      <em>
                        <p>{imageList}</p>
                      </em>
                    );
                  })
                : ""}
            </CardContent>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
