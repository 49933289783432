import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import SignIn from './Auth/Signin';
import {useHistory} from 'react-router-dom'

function getModalStyle() {
  const top = 50;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function LoginModal(props) {

  const history = useHistory();

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogedInClose = (logedin) => {
    setOpen(false);
    console.log({props})
    if(props.id){
      history.push(`/church_details/${props.id}`)
    } else{
  //  window.location.reload()
    }
   
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <SignIn close={()=>handleClose()} logedInClose={()=>handleLogedInClose()}/>
    </div>
  );

  return (
    <div>
      <p onClick={handleOpen}>
       {props.routeLogin? props.routeLogin: 'Login'}
      </p>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
