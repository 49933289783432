import React, { useState, useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { useMutation, gql } from "@apollo/client";

import UserContext from "../../context/auth-context";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CHANGE_PASSWORD = gql`
  mutation ($email: String!, $password: String!, $newPassword: String!) {
    changePassword(
      email: $email
      password: $password
      newPassword: $newPassword
    ) {
      email
    }
  }
`;

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [comfirmPassword, setComfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const user = useContext(UserContext);

  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const [error, setError] = useState([]);

  const handleChangePassword = (e) => {
    e.preventDefault();
    setError([]);
    let errors = [];
    if (newPassword !== comfirmPassword) {
      setError(errors.push("Passwords don't match"));
    }
    if (newPassword.length < 6) {
      setError(errors.push("Password is too short"));
    }
    if (errors.length === 0) {
      changePassword({
        variables: {
          email: user.userDetails.email,
          password,
          newPassword,
        },
      })
        .catch((res) => {
          res.graphQLErrors.map((err) => errors.push(err.message));

          setError(errors);
          return;
        })
        .then(() => {
          if (errors.length === 0) {
            setError([]);
            setSuccess(true);
          }
        });
    } else {
      setError(errors);
    }
  };
  const classes = useStyles();
  if (!success) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          {console.log({ user })}
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="email"
              label="Email"
              type="text"
              id="email"
              value={user?.userDetails?.email}
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Current Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newpassword"
              label="newPassword"
              type="password"
              id="newpassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="comfirmpassword"
              label="comfirmPassword"
              type="password"
              id="comfirmpassword"
              autoComplete="comfirm-password"
              value={comfirmPassword}
              onChange={(e) => setComfirmPassword(e.target.value)}
            />
            <p style={{ color: "red" }}>{error}</p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => handleChangePassword(e)}
            >
              Change Password
            </Button>
          </form>
        </div>
      </Container>
    );
  } else {
    return <p>Password Reset Successfully</p>;
  }
};

export default ChangePassword;
