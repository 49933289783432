import React, { useEffect, useState, useContext } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Image from "../../Images/church_icon.png";
import LeftSideDetail from "../LeftSideDetail/LeftSideDetail";
import RightSideDetail from "../RightSideDetail/RightSideDetail";
import ChurchDetailDesc from "../ChurchDetail/ChurchDetailDesc";
import ChurchDetailAdd from "../ChurchDetail/ChurchDetailAdd";
import SearchHeader from "../Header/SearchHeader";
import "./Detail.css";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MapDetail from "../MapDetail";
import UserContext from "../../context/auth-context";
import EventCalender from "../Event/EventCalender";

const ADDLOCATION = gql`
  mutation ($id: ID!, $lat: Float, $lng: Float) {
    addCoordinate(id: $id, lat: $lat, lng: $lng) {
      street
      country
      lat
    }
  }
`;

const ADD_SUBSCRIPTION = gql`
  mutation ($userId: Int!, $churchId: Int!) {
    addSubscription(userId: $userId, churchId: $churchId) {
      church {
        name
      }
    }
  }
`;

function Detail({ match }) {
  const user = useContext(UserContext);

  const history = useHistory();
  const [showMap, setShowMap] = useState(false);

  const [updateLocation] = useMutation(ADDLOCATION);
  const [subscriptionToChurch] = useMutation(ADD_SUBSCRIPTION);

  const matches = useMediaQuery("(min-width:300px)");
  const matchesDesktop = useMediaQuery("(min-width:1024px)");

  const [images, setImages] = useState([]);

  //  const images = ['https://images.unsplash.com/flagged/photo-1572644973628-e9be84915d59?ixid=MXwxMjA3fDB8MHxzZWFyY2h8Mnx8bGFsaWJlbGF8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60','https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.lrM9d4C-07DRzwoqk3KkiwHaE7%26pid%3DApi&f=1','https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd2g8igdw686xgo.cloudfront.net%2F20335798_1494558779.7212.jpg&f=1&nofb=1']

  const { data, loading, error, refetch } = useQuery(
    gql`
      query church($id: ID!) {
        church(id: $id) {
          id
          name
          title
          description
          image
          establishedAt
          contact {
            email
            facebook
            phoneNumber
            website
            youtube
          }
          operation {
            kidaseStartDay
            kidaseStartTime
            kidaseEndTime
            sundaySchoolStartDay
            sundaySchoolStartTime
            sundaySchoolEndTime
          }
          address {
            street
            city
            state
            zipcode
            country
            lat
            lng
            hasLocation
          }
          additionalTabots {
            name
          }
          events {
            title
            start
            end
            status
          }
        }
      }
    `,
    {
      variables: {
        id: match?.params?.id,
      },
    }
  );

  const [churchLocation, setChurchLocation] = useState({});

  const [currentImage, setCurrentImage] = useState(0);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    console.log({ data, error });
    if (data) {
      if (church.address.hasLocation) {
        setChurchLocation({
          lat: church.address.lat,
          lng: church.address.lng,
        });
        setShowMap(true);
      }
      church.image && setImages(church.image.split(","));
      setImageSrc(church.image.split(",")[0]);
    }
  }, [data]);
  if (loading) {
    return <CircularProgress />;
  }

  let church;
  // if (error) {
  // if the token is expired or invalid
  // localStorage.removeItem("token");
  // return window.location.reload();
  // }
  if (!error) {
    church = data.church;
  }

  const handleImageError = () => {
    setImageSrc(Image);
  };

  const DetailHeader = () => {
    return (
      <div className='detail_header'>
        <h1>{`${church.title} ${church.name}`}</h1>
        {/* {user.userDetails.userId ? (
          <h5
            onClick={() =>
              subscriptionToChurch({
                variables: {
                  userId: parseInt(user.userDetails.userId),
                  churchId: parseInt(match.params.id),
                },
              })
                .then((resp) =>
                  alert("You are now subscribed to: " + church.name)
                )
                .catch((error) => {
                  console.log(error.message);
                  // alert(error.message)
                })
            }
          >
            Subscribe
          </h5>
        ) : (
          <h5 onClick={() => history.push("/signin")}>Subscribe</h5>
        )} */}
      </div>
    );
  };

  const renderLeftSideDetail = () => {
    return (
      <div className='left_side_detail'>
        <LeftSideDetail church={church} />
      </div>
    );
  };

  // let src = !church.image ? Image : church.image;

  const styleImage = {
    width: "80%",
    height: "50vh",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.24)",
    borderRadius: "10px",
  };

  const styleTumbnail = {
    width: "75px",
    height: "75px",
    padding: "5px",
    borderRadius: "10%",
  };

  const styleSelectedTumbnail = {
    width: "75px",
    height: "75px",
    padding: "5px",
    boxShadow: "0 14px 18px rgba(0, 0, 0, 0.24)",
    borderRadius: "10%",
  };

  const handleForward = () => {
    if (images.length === currentImage + 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
    return;
  };

  const handleBackward = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
    return;
  };

  return (
    <>
      {data ? (
        <div className='church_details'>
          {/* <Header /> */}
          <SearchHeader detailHeader={<DetailHeader />} />
          {user && console.log({ user, userID: user.userDetails.userId })}
          <Grid container spacing={3} md={12}>
            <Grid item xs={3} md={3}>
              {/* render on only desktop */}
              {matchesDesktop && renderLeftSideDetail()}
            </Grid>

            <Grid item xs={12} lg={6} md={4}>
              <ChurchDetailAdd church={church} churchAddress={churchLocation} />

              <Grid item lg={12} md={12} xs={12}>
                {!church.image ? (
                  <img
                    alt='church'
                    className='detail_image'
                    src={imageSrc}
                    onError={handleImageError}
                    style={styleImage}
                  />
                ) : (
                  <div style={{ position: "relative" }}>
                    {images.length > 1 && (
                      <>
                        {/* < ArrowBackIosIcon style={{ position:'absolute', top: '50%', left: '10%', color:'#00c1da'}} onClick={handleBackward}/>
                                   <ArrowForwardIosIcon style={{float:'right', position:'absolute', top: '50%', right: '10%', color:'#00c1da'}} onClick={handleForward} /> */}
                      </>
                    )}
                    <img
                      alt='church'
                      className='detail_image'
                      src={images[currentImage]}
                      style={styleImage}
                    />
                  </div>
                )}
                {images.length > 1 && (
                  <div>
                    <ArrowBackIosIcon
                      style={{
                        padding: "30px 10px 30px 0px",
                        color: "#00c1da",
                      }}
                      onClick={handleBackward}
                    />

                    {images.map((image, i) => {
                      return (
                        <img
                          key={i}
                          alt={`altimage${i}`}
                          src={image}
                          style={
                            currentImage === i
                              ? styleSelectedTumbnail
                              : styleTumbnail
                          }
                          onClick={() => setCurrentImage(i)}
                        />
                      );
                    })}
                    <ArrowForwardIosIcon
                      style={{
                        padding: "30px 0px 30px 10px",
                        color: "#00c1da",
                      }}
                      onClick={handleForward}
                    />
                  </div>
                )}
              </Grid>
              <ChurchDetailDesc
                church={church}
                churchAddress={churchLocation}
              />
              <EventCalender
                events={church.events}
                churchID={church.id}
                user={user}
                refetch={refetch}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={5} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  {/* render on only Mobile */}
                  {matches && !matchesDesktop && renderLeftSideDetail()}
                </Grid>
                <Grid item xs={12} sm={5} md={12} lg={12}>
                  {showMap ? (
                    <MapDetail churchAddress={churchLocation} church={church} />
                  ) : (
                    <CircularProgress disableShrink />
                  )}
                  <div className='right_side_detail'>
                    <RightSideDetail church={church} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          {console.log({ error, data })}
          {`${error}`}
        </div>
      )}
    </>
  );
}

export default Detail;
