import React from "react";
import "./ChurchDetailDesc.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useTranslation } from "react-i18next";

function ChurchInfo({ church, churchAddress }) {
  const { t } = useTranslation();

  const longText = church.description;

  return (
    <div>
      <div className='description'>
        <div className='description_content'>
          <h2>{t("detail.6")}</h2>
          {church.description !== "" ? (
            <ReactReadMoreReadLess
              charLimit={500}
              readMoreText={"Read more ▼"}
              readLessText={"Read less ▲"}
              readMoreClassName='read-more-less--more'
              readLessClassName='read-more-less--less'
            >
              {longText}
            </ReactReadMoreReadLess>
          ) : (
            <ReactReadMoreReadLess
              charLimit={200}
              readMoreText={"Read more ▼"}
              readLessText={"Read less ▲"}
              readMoreClassName='read-more-less--more'
              readLessClassName='read-more-less--less'
            >
              {t("description.content")}
            </ReactReadMoreReadLess>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChurchInfo;
