import React from "react";
import "./RightSideDetail.css";
import { useTranslation } from "react-i18next";

function RightSideDetail({ church }) {
  const { t } = useTranslation();
  return (
    <div className='church_info_side'>
      {/*This section can be included inside Events */}
      {/* <ul>
        <li className="church_show_only">
          <h5>HOURS OF OPERATION</h5>
          <ul className="list_detail">
            <li>Kidase: Every {church.operation.kidaseStartDay}</li>
            <li>
              Sunday School: {church.operation.sundaySchoolStartDay}{" "}
              {church.operation.sundaySchoolStartTime} -{" "}
              {church.operation.sundaySchoolEndTime}
            </li>
          </ul>
        </li>
        <li className="church_show_only">Events</li>
      </ul> */}
    </div>
  );
}

export default RightSideDetail;
