import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles, Typography } from "@material-ui/core";
import {
  is_email,
  is_phoneNumber,
  is_facebook,
  is_youTube,
  is_validURL,
} from "./Validate";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  grid_left: {
    borderleft: "2px solid lightgrey",
    [theme.breakpoints.down("sm")]: {
      border: "none",
    },
  },
  textField: {
    marginBottom: "15px",
  },
  h2: {
    textAlign: "left",
  },
  redStar: {
    color: "red",
  },
  required: {
    color: "red",
    position: "relative",
    display: "flex",
  },
  picker: {
    border: "1px solid rgba(0,0,0,.2) !important",
    padding: "5px 5px",
    borderRadius: "5px",
    marginBottom: "15px",
  },
}));
export default function AddressForm(props) {
  const classes = useStyles();
  const [touched, settouched] = useState(false);
  const { t } = useTranslation();
  const handleTouch = () => {
    settouched(true);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} spacing={3} className={classes.grid_left}>
          <h2 className={classes.h2}>{t("contact.contact")}</h2>
          <Grid item>
            <Typography align='left'>
              {t("contact.email")} {<span className={classes.redStar}>*</span>}
            </Typography>
            <TextField
              required
              id='email'
              name='email'
              onBlur={(e) => handleTouch(e)}
              className={classes.textField}
              placeholder='info@eotc.com'
              variant='outlined'
              fullWidth
              value={props?.state?.email}
              onChange={(e) => props.handleChange(e)}
            />

            {touched && props?.state?.email?.length === 0 ? (
              <p className={classes.required}>required</p>
            ) : touched && !is_email(props.state.email) ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item>
            <Typography align='left'>
              {t("contact.phoneNumber")}
              <span className={classes.redStar}>*</span>
            </Typography>
            <TextField
              required
              id='phoneNumber'
              name='phoneNumber'
              onBlur={(e) => handleTouch(e)}
              className={classes.textField}
              placeholder='+251911018810'
              variant='outlined'
              fullWidth
              value={props?.state?.phoneNumber}
              onChange={(e) => props.handleChange(e)}
            />
            {touched && props?.state?.phoneNumber?.length === 0 ? (
              <p className={classes.required}>required</p>
            ) : touched && !is_phoneNumber(props.state.phoneNumber) ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item md={6} xs={12} spacing={3}>
          <h2 className={classes.h2}>{t("social.socialMedia")} </h2>
          <Grid item>
            <Typography align='left'>{t("social.youTube")} </Typography>
            <TextField
              id='youtube'
              onBlur={(e) => handleTouch(e)}
              className={classes.textField}
              name='youtube'
              placeholder='https://www.youtube.com/watch?v=WNZehEJJPAg'
              variant='outlined'
              fullWidth
              value={props?.state?.youtube}
              onChange={(e) => props.handleChange(e)}
            />
            {touched &&
            !is_youTube(props.state.youtube) &&
            props.state.youtube !== "" ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item>
            <Typography align='left'>{t("social.facebook")} </Typography>
            <TextField
              id='facebook'
              name='facebook'
              onBlur={(e) => handleTouch(e)}
              className={classes.textField}
              placeholder='https://www.facebook.com/eotcinfo'
              variant='outlined'
              fullWidth
              value={props?.state?.facebook}
              onChange={(e) => props.handleChange(e)}
            />
            {touched &&
            !is_facebook(props.state.facebook) &&
            props.state.facebook !== "" ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography align='left'>{t("social.website")}</Typography>
            <TextField
              required
              id='website'
              className={classes.textField}
              name='website'
              onBlur={(e) => handleTouch(e)}
              placeholder='https://www.eotc.info.com/home'
              variant='outlined'
              fullWidth
              value={props?.state?.website}
              onChange={(e) => props.handleChange(e)}
            />
            {touched &&
            !is_validURL(props.state.website) &&
            props.state.website !== "" ? (
              <p className={classes.required}>Invalid Input</p>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
