import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import countries from "../Search/countries.json";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import useCities from "./useCities";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  textFeild: {
    fontSize: "16px !important",
    lineHeight: "1px",
    borderRadius: "50%",
    marginBottom: "15px",
    "&.MuiInputBase-input": {
      height: "0.1876em",
    },
  },
  picker: {
    border: "none !important",

    "&.MuiInputBase-input": {
      border: "none",
    },
    // outline: "1px solid grey"
  },
  input: {
    display: "none",
  },
  description: {
    height: "100px !important",
    marginTop: "10px",
  },
  grid_left: {
    borderRight: "2px solid lightgrey",
  },
  label: {
    float: "left",
    paddingBlockEnd: "2px",
  },
  church_label: {
    textAlign: "left !important",
    color: "black",
  },
  additional_tabot: {
    marginBottom: "10px",
  },
  redStar: {
    color: "red",
  },
  required: {
    color: "red",
  },
});

export default function AddressForm(props) {
  const classes = useStyles();
  const [touched, settouched] = useState(false);
  const { states, cities } = useCities(props.state);
  const { t } = useTranslation();

  const handleTouch = () => {
    settouched(true);
  };

  const is_usZipCode = (str) => {
    const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;

    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };
  const is_streetAddress = (str) => {
    const regexp = /^[a-zA-Z0-9\s,'-]*$/;

    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography align='left'>
            {t("address.country")} <span className={classes.redStar}>*</span>
          </Typography>
          <Select
            align='left'
            required
            id='country'
            name='country'
            placeholder='United States'
            variant='outlined'
            onBlur={handleTouch}
            fullWidth
            autoComplete='shipping country'
            value={props?.state?.country}
            onChange={(e) => props.handleChange(e)}
          >
            <MenuItem value=''>--{t("select.1")}--</MenuItem>
            {countries.map((country, index) => (
              <MenuItem key={index} value={index}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align='left'>
            {t("address.state")} <span className={classes.redStar}>*</span>
          </Typography>
          <Select
            align='left'
            id='state'
            name='state'
            onBlur={handleTouch}
            placeholder='Maryland'
            variant='outlined'
            fullWidth
            value={props?.state?.state}
            onChange={(e) => props.handleChange(e)}
          >
            <MenuItem value=''>--{t("select.2")} --</MenuItem>
            {states?.map((city, index) => (
              <MenuItem key={index} value={index}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align='left'>
            {t("address.city")} <span className={classes.redStar}>*</span>
          </Typography>
          <Select
            align='left'
            required
            id='city'
            name='city'
            onBlur={handleTouch}
            placeholder='Silver Spring'
            variant='outlined'
            fullWidth
            autoComplete='shipping address-level2'
            value={props?.state?.city}
            onChange={(e) => props.handleChange(e)}
          >
            <MenuItem value=''>--{t("select.3")}--</MenuItem>
            {cities.map((city, index) => (
              <MenuItem key={index} value={index}>
                {city}
              </MenuItem>
            ))}
          </Select>
          {touched && props.state.city === "" ? (
            <p className={classes.required}>required</p>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography align='left'>
            {t("address.street")} <span className={classes.redStar}>*</span>
          </Typography>
          <TextField
            required
            id='street'
            name='street'
            onBlur={handleTouch}
            placeholder='123 xyz Street'
            variant='outlined'
            fullWidth
            value={props?.state?.street}
            onChange={(e) => props.handleChange(e)}
          />

          {touched && props.state.street.length === 0 ? (
            <p className={classes.required}>required</p>
          ) : touched && !is_streetAddress(props.state.street) ? (
            <p className={classes.required}>Invalid Input</p>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography align='left'>
            {t("address.zipCode")} <span className={classes.redStar}>*</span>
          </Typography>
          <TextField
            required
            id='zipcode'
            name='zipcode'
            onBlur={handleTouch}
            placeholder='11005'
            variant='outlined'
            fullWidth
            autoComplete='shipping postal-code'
            value={props?.state?.zipcode}
            onChange={(e) => props.handleChange(e)}
          />

          {touched && props.state.zipcode.length === 0 ? (
            <p className={classes.required}>required</p>
          ) : touched && !is_usZipCode(props.state.zipcode) ? (
            <p className={classes.required}>Invalid Input</p>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
