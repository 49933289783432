import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import Header from "../Header/SearchHeader";
import ChurchInfo from "../ChurchInfo/ChurchInfo";
import ChurchInfoSide from "../ChurchInfoSide/ChurchInfoSide";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import "./Church.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AllChurchMap from "../AllChurchMap";
import Footer from "../Footer/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  churches: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  desktop: {
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  Mobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  [theme.breakpoints.down("xs")]: {
    margin: 0,
    "& > .Muigrid-root .MuiGrid-item": {
      padding: 0,
    },
  },
}));

const ADDLOCATION = gql`
  mutation ($id: ID!, $lat: Float, $lng: Float) {
    addCoordinate(id: $id, lat: $lat, lng: $lng) {
      street
      country
      lat
    }
  }
`;

const Church = () => {
  const classes = useStyles();

  const [showMap, setShowMap] = useState(false);
  const [churchLocations, setChurchLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [cursor, setCursor] = useState(0);
  const [perPage, setPerPage] = useState(5);

  const { data, loading, error, refetch } = useQuery(
    gql`
      query allChurches($query: queryType, $limit: Int, $cursor: Int) {
        churches(query: $query, limit: $limit, cursor: $cursor) {
          count
          rows {
            id
            name
            title
            image
            address {
              street
              city
              state
              zipcode
              country
              lat
              lng
              hasLocation
            }
            additionalTabots {
              name
            }
          }
        }
      }
    `,
    {
      variables: {
        query: {},
        limit: parseInt(perPage),
        cursor: cursor,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const Slugify = (church) => {
    // concatinate the address with '+' for google to respond with latLon
    const street = church?.address?.street
      ? church?.address?.street.replace(/\s/g, "+")
      : "";
    const city = church?.address?.city
      ? church?.address?.city?.replace(/\s/g, "+")
      : "";
    const state = church?.address?.state
      ? church?.address?.state?.replace(/\s/g, "+")
      : "";
    const country = church?.address?.country
      ? church?.address?.country?.replace(/\s/g, "+")
      : "";
    const zipcode = church?.address?.zipcode
      ? church?.address?.zipcode?.replace(/\s/g, "+")
      : "";

    return `${street + city + state + country + zipcode}`;
  };

  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    if (data) {
      let locs = [];
      data.churches.rows.length > 0 &&
        data.churches.rows.map((church) => {
          // if current church doesnt have a location stored in the DB go to google geocoder API and get the Latitude and long
          if (church?.address?.hasLocation) {
            locs = [
              ...locs,
              { lat: church.address.lat, lng: church.address.lng, church },
            ];
          }
        });
      setShowMap(true);
      setChurchLocations(locs);
    }
  }, [data]);

  const [showPagination, setShowPagination] = useState(true);
  const [query, setQuery] = useState({});
  const searchChurch = (searchQuery) => {
    setQuery(searchQuery);
    setPerPage(6);
    refetch({
      query: searchQuery,
      limit: parseInt(perPage),
      cursor: 0,
    });
  };

  const onPageChange = (event, value) => {
    const cursor = (value - 1) * perPage;
    setPage(value);
    refetch({
      query,
      limit: parseInt(perPage),
      cursor,
    });
  };
  const searchResultPagination = (query, total, pages = []) => {
    return true;
  };
  const renderChurchSkeleton = () => {
    return (
      <div style={{ marginTop: "10px" }}>
        <div style={{ display: "flex" }}>
          <div>
            <Skeleton variant='rect' width={100} height={100} />
          </div>
          <div style={{ margin: "10px" }}>
            <Skeleton variant='text' width={400} />
            <Skeleton variant='text' width={100} />
            <Skeleton variant='text' width={300} />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div>
        <Header searchChurch={searchChurch} />
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={12} className={classes.desktop}>
              <ChurchInfoSide />
            </Grid>

            <Grid
              item
              lg={6}
              xs={12}
              md={12}
              className={classes.churches}
              style={{ textAlign: "center" }}
            >
              {/* If user is login present the link or show popup for login */}
              {!loading &&
                !error &&
                data.churches.rows.map((eachChurch) => (
                  <ChurchInfo key={eachChurch.id} church={eachChurch} />
                ))}

              {loading ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      marginLeft: "50px",
                    }}
                  >
                    <div>
                      {renderChurchSkeleton()}
                      {renderChurchSkeleton()}
                      {renderChurchSkeleton()}
                    </div>
                  </div>
                </>
              ) : (
                !error &&
                data.churches.rows.length < 1 && (
                  <div style={{ textAlign: "left" }}>Search Not Found</div>
                )
              )}
              {data && showPagination && !loading && (
                <div>
                  <Pagination
                    count={Math.ceil(data.churches.count / perPage)}
                    page={page}
                    style={{ display: "inline-flex", margin: 50 }}
                    color='secondary'
                    variant='outlined'
                    shape='rounded'
                    onChange={onPageChange}
                  />
                </div>
              )}
            </Grid>
            {loading && (
              <div style={{ marginTop: "10px" }}>
                <Grid item lg={6} xs={12} md={12}>
                  <Skeleton variant='rectangle' height={300} width={500} />
                </Grid>
              </div>
            )}
            {!loading && (
              <Grid item lg={6} xs={12} md={12}>
                {showMap && data.churches.rows.length > 0 && (
                  <AllChurchMap
                    markers={churchLocations}
                    searchResult={searchData}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Church;
